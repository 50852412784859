<template>
  <div>
    <!-- <headPage></headPage> -->
    <div class="agreement">
      <h1 align="center">
        <strong>线上申请保函协议</strong><strong> </strong>
      </h1>
      <p ><strong>1、协议条款的确认与接受</strong><strong> </strong><br />
    <strong>1.1本协议系由</strong><strong>北京银保慧通科技有限公司</strong><strong>（以下简称“</strong><strong>甲</strong><strong>方”）与通过</strong><strong>甲</strong><strong>方技术服务支持委托申请</strong><strong>各银行、担保公司、保险公司等机构的</strong><strong>保函（以下简称“保函”）的客户（以下简称“</strong><strong>乙</strong><strong>方”）对技术服务委托事项、委托担保事项所订立的有效合约</strong><strong>。</strong><strong> </strong><br />
  <strong>1.2本协议内容中以加粗方式显著标识的条款，请您着重阅读。您</strong><strong>完成保函费支付，包含但不限于企业网银支付、扫码支付、线下转账汇款、他人代支付，</strong><strong>即视为完全接受本协议，在</strong><strong>支付</strong><strong>之前请您再次确认已知悉并完全理解本协议的全部内容。</strong><strong> </strong><br />
  <strong>2、各方主体</strong><strong> </strong><br />
  2.1甲方为北京银保慧通科技有限公司，是依法成立的有限责任公司，依法可从事技术推广、技术转让、技术咨询、技术服务等业务，为本协议中技术服务方。 <br />
  2.2乙方为银保镖保函系统平台的注册用户，是依法成立并合法经营的企业法人、法定代表人，或公司的合法继受人，为本协议中保函申请人。 <br />
  <strong>3. 陈述及保证</strong><strong> </strong><br />
  本协议双方作出如下声明和保证： <br />
  3.1其依据中国法律具备签订本协议的主体资格，拥有签订本协议并且履行其在本协议项下全部义务的完全民事权利能力和民事行为能力及相关的合法资质。 <br />3.2其已经按照其章程或者其它内部管理文件的要求取得合法、有效的授权，且不会违反对其有约束力的任何协议、合同和其他法律文件；已经或将会取得签订和履行本协议所需的一切有关批准、许可、备案或者登记。 <br />
  3.3知悉并同意本协议的全部条款，签署和履行本协议系基于其真实意思表示。 <br />
  <strong>4、技术服务委托事项</strong><strong> </strong><br />
  4.1乙方为实现保函开立便利，委托甲方作为技术服务方，通过银保镖保函系统平台（以下简称“保函平台”）为开立保函提供技术服务，技术服务范围包括但不限于保函申请、保函信息填写、保函信息代填写、资料传输、资料代传输、信息采集及查询、缴纳费用、代收保函等贯穿保函申请、开立、返回的所有事项。 <br />
  4.2乙方已知悉并同意，甲方作为技术服务方为实现保函申请事项而采取的必要技术服务措施，包括但不限于对乙方信息查询、采集、储存、代提交、代上传等，并有权合理将乙方提供的资料提供给保函办理相关方。 <br />
  <strong>5、委托担保事项</strong><strong> </strong><br />
  5.1乙方知悉并同意，为实现乙方开立保函目的，通过甲方委托银行、担保公司、保险公司等机构作为代理保函申请人，以乙方为被保证人申请并向受益人出具保函，并由银行、担保公司、保险公司等机构为乙方开立保函。 <br />
  5.2银行、担保公司、保险公司等机构提供保证的金额、范围、方式、期间（包括续保期间）等具体内容以乙方确认后经保函平台推送为准，乙方在保函平台填写保函开立信息，或由甲方代填写、代上传保函开立相关信息及资料，并缴纳相关费用后，即视为对保函内容做出确认。 <br />
  5.3 银行、担保公司、保险公司等机构有权根据乙方经营情况、申请开立保函对应的项目情况独立判断是否符合承保条件准入，并独立决定对不符合银行、担保公司、保险公司等机构要求的保函申报进行驳回，并通过包括但不限于保函平台推送等途径及时通知乙方。 <br /><strong><u>5.4</u></strong><strong><u>乙方</u></strong><strong><u>知悉并同意，针对银行、担保公司、保险公司等机构在本协议项下依法应承担的保证责任向银行、担保公司、保险公司等机构承担全部赔付义务。</u></strong><strong><u> </u></strong><br />
  <strong>6、费用支付及发票开具</strong><strong> </strong><br />
  6.1本协议项下，<strong>乙方</strong><strong>应基于技术服务及委托担保事项向</strong><strong>甲方</strong><strong>支付本协议项下服务费用，</strong>具体费用以乙方在保函平台中填写保函开立信息后测算结果为准，乙方按照系统测算金额缴款后，即视为对相关委托事项产生的一切费用做出确认。 <br />
  6.2乙方应将6.1条中技术服务委托事项产生的技术服务费用缴纳至甲方指定的银行账户，具体账户信息、支付方式以保函平台显示为准。 <br />
  6.3本协议项下，<strong>甲方</strong><strong>应基于</strong><strong>乙方</strong><strong>申请委托担保事项向银行、担保公司、保险公司等机构支付委托担保费用及保函申请手续费，</strong>费用以乙方在保函平台中填写保函开立信息后系统测算结果为准，甲方按照系统测算金额缴款后，即视为对委托担保事项产生的委托担保费用做出确认。 <br />
  <strong>6.</strong><strong>4甲方</strong><strong>对</strong><strong>乙</strong><strong>方按本协议6.1条约定缴纳的技术服务委托费用开具发票。</strong><strong> </strong><br />
  6.5本条所述全部费用应于保函开立前缴纳，一旦银行出具保函，不予退还。 <br />
  <strong>7. </strong><strong>乙方</strong><strong>的权利和义务</strong><strong> </strong><br />
  7.1乙方有权根据本协议及保函平台发布的相关规则，利用保函品台在线办理保函，并通过保函平台对已经成功办理的在线保函，进行查询、查看、电子版保存等管理功能；<br />7.2乙方须对在保函平台发生的所有活动（包括但不限于注册登录、企业认证、保函在线办理申请/代办理申请、在线支付、开具电子发票、网上点击审阅并确定是否确定同意各类协议、规则等）承担责任。因乙方的过错导致的任何损失由乙方自行承担； <br />
  7.3乙方须对所上传资料/代上传资料、信息的真实性、合法性、有效性等负责，因乙方信息或资料问题导致保函申请延误、无效等一切后果均由乙方承担。 <br />
  7.4乙方承诺在使用保函平台实施的所有行为遵守法律、法规、行政规章和保函平台的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，乙方将以自己的名义独立承担相应的法律责任； <br />
  7.5未经保函平台书面允许，乙方不得将保函平台的任何流程、截图、资料以及在保函平台上所展示的任何信息作商业性利用（包括但不限于以复制、修改、翻译等形式制作衍生作品、分发或公开展示）； <br />
  7.6乙方同意接收来自保函平台的信息，包括但不限于公告信息、保函状态变更提醒、保函办理成功、驳回提醒等。 <br />
  7.7乙方应充分配合甲方及银行、担保公司、保险公司等机构基于保函开立、管理事项对乙方财务状况、信用状况等企业经营情况进行信息采集与审查。 <br />
  7.8乙方应按照甲方及银行、担保公司、保险公司等机构的要求提供反应企业真实信息的基础资料，并对所提供资料的真实性、完整性和有效性负责，并保证能满足办理保函的有关要求，乙方如违反上述义务导致审核后不能出具保函的，乙方应承担因此产生的手续费、评审费等费用。 <br />
  7.9当发生独立保函欺诈情形时，乙方应依据相关法律规定，及时向具有管辖权的人民法院申请止付独立保函项下的款项（以下简称“申请止付”），或者授权银行、担保公司、保险公司等机构代为行使申请止付，若乙方怠于自行行使且拒不委托银行、担保公司、保险公司等机构行使申请止付的，银行、担保公司、保险公司等机构有权拒绝承担保证责任，并有权拒绝为乙方新的具体业务提供保证。 <br />
  <strong>8. 违约责任承担及追偿</strong><strong> </strong><br />
  <strong>8.1 保证责任承担</strong><strong> </strong><br />
  <strong>银行、担保公司、保险公司等机构</strong><strong>在收到代偿通知后，有权独立判断是否符合本协议及法律文件的约定，并决定承担保证责任的金额及给付方式（如可根据要求直接将索赔款支付给受益人），</strong><strong>银行、担保公司、保险公司等机构</strong><strong>将及时通知</strong><strong>乙方</strong><strong>，但无须征得</strong><strong>乙</strong><strong>方同意。</strong><strong> </strong><br />
  <strong>一旦受益人索赔，无论</strong><strong>银行、担保公司、保险公司等机构</strong><strong>是否已实际赔偿，受益人索赔的款项即已构成</strong><strong>乙方</strong><strong>对受益人应付债务，亦即构成本协议项下</strong><strong>乙方</strong><strong>对</strong><strong>银行、担保公司、保险公司等机构</strong><strong>的债务，</strong><strong>银行、担保公司、保险公司等机构</strong><strong>即可直接向</strong><strong>乙方</strong><strong>索偿，</strong><strong>乙方</strong><strong>应无条件地立即向</strong><strong>银行、担保公司、保险公司等机构</strong><strong>支付保函担保范围内受益人索赔的全部金额。</strong><strong> </strong><br />
  <strong>受益人索赔，银行或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>在审查有关索赔文件或证明，确认符合保函约定的索偿条件后，无需事先征得</strong><strong>乙方</strong><strong>的同意即可支付索赔款项，不受</strong><strong>乙方</strong><strong>与受益人之间的任何纠纷的影响。一旦依据保函向受益人承担保证责任赔付，即使存在受益人欺诈，或因法律规定（包括国内外法律）、法院判决、仲裁机构裁决等导致</strong><strong>银行、担保公司、保险公司等机构</strong><strong>在保函有效期届满后对外赔付等情形，不影响</strong><strong>银行、担保公司、保险公司等机构</strong><strong>或银行对</strong><strong>乙方</strong><strong>追偿的权利。</strong><strong> </strong><br />
  <strong>银行、担保公司、保险公司等机构</strong><strong>按本协议项下保函承担保证责任，并/或承担因保函引起的其他责任（包括但不限于因保函无效承担的赔偿责任等），无论</strong><strong>银行、担保公司、保险公司等机构</strong><strong>因此承担的责任额是否超出本协议约</strong><strong>定及保函约定的担保金额、无论</strong><strong>银行、担保公司、保险公司等机构</strong><strong>实际履行义务的时间是否超出保函约定的有效期，</strong><strong>银行、担保公司、保险公司等机构</strong><strong>在保函项下承担保证责任的代偿款项，及/或因</strong><strong>银行、担保公司、保险公司等机构</strong><strong>履行赔付义务产生的赔偿款项均构成本协议项下</strong><strong>乙方</strong><strong>对</strong><strong>银行、担保公司、保险公司等机构</strong><strong>的债务，均属于本协议项下反担保范围。</strong><strong> </strong><br />
  <strong>8.2 追偿权</strong><strong> </strong><br />
  <strong>乙方</strong><strong>应在</strong><strong>银行、担保公司、保险公司等机构</strong><strong>全部或部分履行保证合同的代偿义务当日，无条件向</strong><strong>银行、担保公司、保险公司等机构</strong><strong>全额支付包括</strong><strong>银行、担保公司、保险公司等机构</strong><strong>按保证合同约定已向银行支付的本金、利息、罚息、逾期利息、复利、违约金、损害赔偿金、实现债权的费用等全部代偿款项（以下合称“代偿款项”），并自</strong><strong>银行、担保公司、保险公司等机构</strong><strong>实际支付代偿款项之日起以代偿款项为基数按每日按万分之伍向</strong><strong>银行、担保公司、保险公司等机构</strong><strong>支付罚息直到甲方付清之日止。在</strong><strong>乙方</strong><strong>付清上述全部款项之前，</strong><strong>银行、担保公司、保险公司等机构</strong><strong>有权拒绝为新的具体业务提供保证。</strong><strong> </strong><br />
  <strong>如</strong><strong>银行、担保公司、保险公司等机构</strong><strong>因保证合同无效或其他法律规定事由承担任何赔偿责任的，</strong><strong>乙方</strong><strong>应在</strong><strong>银行、担保公司、保险公司等机构</strong><strong>支付赔偿款当日，无条件向</strong><strong>银行、担保公司、保险公司等机构</strong><strong>全额支付全部赔偿款项（以下称“赔偿款项”），并自</strong><strong>银行、担保公司、保险公司等机构</strong><strong>实际支付赔偿款项之日起以赔偿款项为基数按每日按万分之伍向</strong><strong>银行、担保公司、保险公司等机构</strong><strong>支付罚息直到</strong><strong>乙方</strong><strong>付清之日止。</strong><strong> </strong><br />
  <strong>在</strong><strong>乙方</strong><strong>未按</strong><strong>银行、担保公司、保险公司等机构</strong><strong>要求付清上述全部代偿款项、赔偿款项之前，</strong><strong>银行、担保公司、保险公司等机构</strong><strong>有权拒绝为</strong><strong>乙方</strong><strong>新的具体业务提供保证。</strong><strong> </strong><br />
  <strong>8.3 实现债权的费用</strong><strong> </strong><strong>银行、担保公司、保险公司等机构</strong><strong>向</strong><strong>乙方</strong><strong>行使追偿权、追索担保费用及本协议下的</strong><strong>乙方</strong><strong>的其他债务所产生的实现债权的费用，银行或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>为实现债权，以及解决和债务相关纠纷而已经或将要支付的费用由</strong><strong>乙方</strong><strong>承担，包括但不限于诉讼费、公证费、仲裁费、律师费、财产保全费、差旅费、执行费、评估费、拍卖费、代投保费用等。</strong><strong> </strong><br />
  8.4 同等抗辩权 <br />
  银行、担保公司、保险公司等机构依法可行使与乙方同等的对银行的抗辩权，银行、担保公司、保险公司等机构的此项权利不因乙方放弃相关抗辩权而丧失。 <br />
  8.5 资金划扣 <br />
  乙方欠付本协议下的任何款项的，银行、担保公司、保险公司等机构可委托银行从乙方的银行帐户中扣划相应资金至银行、担保公司、保险公司等机构以偿还欠款。 <br />
  <strong>9. 通知及送达</strong><strong> </strong><br />
  9.1 通知及送达形式 <br />
  三方之间的一切通知均为书面形式，可由专人送达、挂号邮递、特快专递、传真、电子邮件等有效方式送达。通知在下列日期到达的视为已送达： <br />
  9.1.1专人递送的通知，在专人递送之交付日为有效送达； <br />
  9.1.2以挂号信（付清邮资）发出的通知，在寄出（以邮戳为凭）后的第5日为有效送达； <br />
  9.1.3以特快专递（付清邮资）发出的通知，在寄出（以邮戳为凭）后的第3日为有效送达；<br /> 9.1.4以传真或电子邮件发出的通知，以传真、电子邮件到达对方特定系统的日期为有效送达。因对方电子邮箱原因（包括但不限于无效或被注销）导致电子邮件发送失败的，自发件人发送之时视为送达成功。 <br />
  9.2 联系信息 <br />
  <strong>甲方</strong><strong>有效的送达地址和通讯方式为：</strong><strong><u>住所：北京市海淀区北清路68号院</u></strong><strong><u> 中区8F</u></strong><strong><u> ；联系电话：400-895-8805</u></strong><strong> </strong><br />
  <strong>乙方</strong><strong>确认在</strong><strong>保函平台</strong><strong>填写的联系信息为其有效的送达地址和通讯方式。</strong><strong> </strong><br />
  9.3 司法送达 <br />
  本协议9.2指向的、由当事人确认的联系信息同时是当事人送达各类通知、协议等文件以及发生纠纷时相关文件及法律文书的送达地址。 <br />
  本协议约定的送达地址的适用范围包括非诉阶段和争议进入仲裁、民事诉讼程序后的一审、二审、再审和执行程序，法院可直接通过邮寄或其他方式向双方预留的地址送达法律文书。 <br />
  因一方提供或者确认的送达地址不准确、送达地址变更后未及时依程序告知对方和法院或仲裁机构（若争议已经入司法程序解决）、拒收或指定的接收人拒绝签收等原因，导致相关文件或法律文书未能被该方实际接收的，邮寄送达的，以文书退回之日视为送达之日；直接送达的，送达人当场在送达回证上记明情况之日视为送达之日。 <br />
  乙方进一步确认协议指明的送达地址和电子邮件接收邮箱地址（如有）为本协议所涉诉讼（仲裁）司法文书（包括但不限于起诉状、上诉状、传票、开庭通知书、判决书、裁定书、调解书、限期履行通知书）的送达地址和电子司法文书电子邮件接收邮箱地址，所有司法文书只要发送至本协议指明的乙方送达地址或电子邮件接收邮箱地址即视为送达。乙方亦同意以手机短信方式接收司法文书，如司法部门（仲裁机构）已按乙方联系人在本协议指定的手机号码发送了相关诉讼文书短信，可视为送达。 <br /> 9.4 送达条款独立性 <br />
  本条约定的送达条款属于本协议中独立存在的有关有效送达地址的确认和解决争议方法的条款，本协议及本协议其他条款的效力不影响本条的效力，双方均须依协议约定承担有效送达的法律后果。任何一方变更其联系方式或地址应及时书面通知另一方，否则另一方仍有权将变更前的联系方式或地址视为有效。 <br />
  <strong>10. 违约责任</strong><strong> </strong><br />
  <strong>本协议生效后，</strong><strong>双方</strong><strong>均应履行协议约定的义务，任何一方不履行或不完全履行本协议所约定义务的，应当承担相应的违约责任，并赔偿由此给</strong><strong>另一方</strong><strong>造成的损失。</strong><strong> </strong><br />
  <strong>乙方</strong><strong>违反本协议任一约定或违反任何义务（包括违反陈述和保证），或明确表示或以其行为表明将不履行本协议项下的任一义务的，</strong><strong>甲方</strong><strong>或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>有权书面通知</strong><strong>乙方</strong><strong>纠正其违约行为，如果</strong><strong>乙方</strong><strong>于</strong><strong>甲方</strong><strong>或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>发出上述通知之日起5个工作日内仍未对违约行为予以纠正，则</strong><strong>甲方</strong><strong>或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>有权单独或一并行使下述权利：</strong><strong> </strong><br />
  <strong>10.1要求</strong><strong>乙方</strong><strong>限期纠正违约行为；</strong><strong> </strong><br />
  <strong>10.2要求</strong><strong>乙方</strong><strong>提供担保；</strong><strong> </strong><br />
  <strong>10.3要求</strong><strong>乙方</strong><strong>向</strong><strong>甲方</strong><strong>或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>支付本协议项下保函金额百分之十五的违约金，并赔偿由此给</strong><strong>甲方</strong><strong>及/或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>造成的损失；</strong><strong> </strong><br />
  <strong>10.4要求</strong><strong>乙方</strong><strong>立即向</strong><strong>甲</strong><strong>方或</strong><strong>银行、担保公司、保险公司等机构</strong><strong>指定账户缴存本协议项下保函金额100%作为保证金；</strong><strong> </strong><br />
  <strong>10.5法律或双方签署的其他合同许可的其他救济措施。</strong><strong> </strong><strong>11. 协议的生效、变更和解除</strong><strong> </strong><br />
  11.1 协议生效 <br />
  <strong>本协议为制式合同，</strong><strong>甲乙</strong><strong>双方对协议条款内容核实无误并同意本协议的全部条款。</strong><strong>乙方支付保函费，包含但不限于企业网银支付、扫码支付、线下转账汇款、他人代支付，</strong><strong>即视为已知悉并完全理解本协议的全部内容、完全接受本协议，协议即可生效。</strong><strong> </strong><br />
  11.2 独立性 <br />
  <strong>本协议独立于保证合同，本协议不因保证合同的无效而无效。</strong><strong> </strong><br />
  11.3 协议解除 <br />
  当事人任何一方不得擅自变更或提前解除本合同。如本协议需要变更或解除时，应经当事人协商一致，并达成书面协议。书面协议达成之前，本协议各条款仍然有效。 <br />
  <strong>12. 适用法律及争议的解决</strong><strong> </strong><br />
  12.1 适用法律 <br />
  本协议的制定、解释及其在执行过程中出现的、或与本协议有关的纠纷之解决，受中华人民共和国（不含港澳台地区）现行有效的法律的约束。 <br />
  12.2 争议解决 <br />
  当事人在履行本协议过程中发生的争议，首先由当事人协商解决；协商不成而引发诉讼的，由银行、担保公司、保险公司等机构所在地有管辖权的人民法院管辖。 <br />
  <strong>13. 合同解释</strong><br />除非上下文另有约定，在本协议中： <br />
  13.1本协议所指的“人”包括一个、两个或两个以上的任何人、公司、企业、政府、政府代理机构或任何协会、信托或合伙（无论是否具备独立法人地位）； <br />
  13.2本协议中的“包括”应解释为“包括但不限于”； <br />
  13.3本协议中的“天”，应解释为：本协议中规定按天计算时间的，开始当天不计入，从次日开始计算。时限的最后一天是休息日或者其他法定节假日的，以节假日次日为时限的最后一天。时限的最后一天的截止时间为当日24时； <br />
  13.4本协议中所称的“以上”“以下”“以内”“届满”，包括本数；所称的“不满”“超过”“以外”，不包括本数； <br />
  13.5本协议中，单数应包括复数，反之亦然；指代某个性别应包括其他任何性别；对时间的提及，除文中另有所指外，其余皆指北京时间； <br />
  13.6如果协议条款和附件的意思发生冲突，各方应首先尽力将整个协议作为一个整体来阅读理解，对协议主题最为明确的条款应优先考虑；如果各方本着诚实信用的原则努力按照前句规定解决冲突，但冲突依然存在，则协议条款应优先适用； <br />
  13.7本协议中所有的标题、题目、索引、副标题、小标题仅为方便阅读而加入，概不限制、改变或影响本协议的诠释和执行。 </p>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.agreement {
  width: 1200px;
  margin: 20px auto;

  .title {
    text-align: center;
    font-size: 16px;
  }

  .content_box {
    font-size: 14px;
    line-height: 28px;
  }
}
</style>
